@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

body {
    font-family: "Inter";
    background-color: #ffffff;
    overflow-x: hidden;
    margin: 0;
}

body.dark {
    background-color: #030711;
}

button {
    font-family: "Inter";
}

body .dots {
    background-image: radial-gradient(rgb(25, 25, 25) 1px, transparent 0);
    background-size: 20px 20px;
    background-position: -19px -19px;
}

.dark .dots {
    background-image: radial-gradient(rgb(255, 255, 255) 1px, transparent 0);
    background-size: 20px 20px;
    background-position: -19px -19px;
}


.invisible {
    opacity: 0;
    filter: blur(2px);
    transform: translateX(-100%);
    transition: transform 1s, opacity 1s, border 0.2s, filter 1s;    
}

.show {
    opacity: 1;
    filter: blur(0px);
    transform: translateX(0);
    transition: transform 1s, opacity 1s, border 0.2s, filter 1s;    
}

#card-value:first-child {
    transition-delay: 0.1s;
}

#card-review:nth-child(1) {
    transition-delay: 0.1s;
}
#card-review:nth-child(2) {
    transition-delay: 0.15s;
}
#card-review:nth-child(3) {
    transition-delay: 0.2s;
}

#card-contact.show {
    transition: transform 0.75s, opacity 0.5s, border 0.2s, filter 0.5s;    
}

#card-contact.invisible {
    opacity: 0;
    filter: blur(2px);
    transform: translateY(50%);
    transition: transform 1s, opacity 1s, border 0.2s, filter 1s;    
}

#card-contact.show {
    opacity: 1;
    filter: blur(0px);
    transform: translateY(0);
    transition: transform 1s, opacity 1s, border 0.2s, filter 1s;    
}

#card-contact:nth-child(1) {
    transition-delay: 0.1s;
}
#card-contact:nth-child(2) {
    transition-delay: 0.15s;
}
#card-contact:nth-child(3) {
    transition-delay: 0.2s;
}

.missing-field {
    border: 1px solid rgba(255,0,0, 0.33);
}